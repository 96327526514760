<template>
  <v-app>
    <v-main class="grey lighten-4">
      <v-container v-if="!isAuth" class="py-12">
        <v-card class="login-card elevation-4">
          <v-card-title class="justify-center">
            Вход
          </v-card-title>
          <v-divider />
          <v-row class="pt-6 px-4">
            <v-col cols="12">
              <v-text-field
                v-model="login"
                label="Логин"
                outlined
                :rules="[v => v === 'demo' || 'Неверный логин']"
                validate-on-blur
              />
              <v-text-field
                v-model="password"
                type="password"
                label="Пароль"
                outlined
                :rules="[v => v === 'demo' || 'Неверный пароль']"
                validate-on-blur
              />
            </v-col>
          </v-row>
          <v-divider />
          <v-card-actions class="justify-center py-3">
            <v-btn
              color="primary"
              class="px-10"
              :disabled="!login || !password"
              @click="submit"
            >
              Войти
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>

      <Home v-else />
    </v-main>
  </v-app>
</template>

<script>
import Home from './views/Home.vue';

export default {
  name: 'App',
  components: {
    Home,
  },
  data() {
    return {
      login: '',
      isAuth: false,
      password: '',
    };
  },
  methods: {
    submit() {
      if (this.login === 'demo' && this.password === 'demo') {
        this.isAuth = true;
      }
    },
  },
};
</script>

<style>
.login-card {
  margin: 0 auto;
  max-width: 500px;
  width: 500px;
}
</style>
