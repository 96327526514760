<template>
  <v-list-item :key="user.name">
    <v-list-item-content>
      <v-row>
        <v-col cols="6">
          <v-list-item-title class="mb-3">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ user.science }}
          </v-list-item-subtitle>
        </v-col>
        <v-col cols="2" align-self="center">{{ phoneNumber }}</v-col>
        <v-col cols="4" align-self="center" class="text-truncate">
          {{ email }}
        </v-col>
      </v-row>
    </v-list-item-content>
    <template v-if="showIcons">
      <v-list-item-action>
        <v-btn icon title="Зачислить" @click="approveUser(user)">
          <v-icon color="green accent-4">
            mdi-check-bold
          </v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn icon title="Отказать" @click="rejectUser(user)">
          <v-icon color="red accent-4">
            mdi-close
          </v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
import CyrillicToTranslit from 'cyrillic-to-translit-js';
const cyrillicToTranslit = new CyrillicToTranslit();

const USER_STATUSES = {
  NEW: 'NEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export default {
  props: {
    showIcons: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    approveUser(user) {
      user.status = USER_STATUSES.APPROVED;
    },
    rejectUser(user) {
      user.status = USER_STATUSES.REJECTED;
    },
  },
  computed: {
    phoneNumber() {
      const code = (Math.floor(Math.random() * 900) + 1).toString().slice(0, 2);
      const number = (Math.floor(Math.random() * 90000000) + 1)
        .toString()
        .split('');
      return (
        '+7 (9' +
        code +
        ') ' +
        number.slice(0, 3).join('') +
        '-' +
        number.slice(3, 5).join('') +
        '-' +
        number.slice(5, 7).join('')
      );
    },
    email() {
      return (
        cyrillicToTranslit.transform(this.user.name, '_').toLowerCase() +
        '@mail.ru'
      );
    },
  },
};
</script>

<style></style>
