<template>
  <v-container class="py-8">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-tabs v-model="activeTab" align-with-title grow>
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab v-for="tab in tabs" :key="tab.key">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </v-card-title>
          <v-card-text>
            <v-tabs-items v-model="activeTab">
              <v-list>
                <template v-if="visibleUsers.length">
                  <list-item
                    v-for="(user, index) in visibleUsers"
                    :user="user"
                    :key="user.name"
                    :show-icons="activeTab === 0"
                    :class="{
                      'bordered-bottom': index !== visibleUsers.length - 1,
                    }"
                  />
                </template>
                <p class="ma-0 text-subtitle-1 text-center" v-else>
                  Данных нет.
                </p>
              </v-list>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListItem from '../components/ListItem.vue';
import users from '../fixtures/users.json';
import sciences from '../fixtures/sciences.json';

const USER_STATUSES = {
  NEW: 'NEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export default {
  name: 'Home',
  components: {
    ListItem,
  },
  data() {
    return {
      activeTab: USER_STATUSES.NEW,
      tabs: [
        {
          key: USER_STATUSES.NEW,
          title: 'Новые',
        },
        {
          key: USER_STATUSES.APPROVED,
          title: 'Зачислены',
        },
        {
          key: USER_STATUSES.REJECTED,
          title: 'Отказ',
        },
      ],
      users: [],
    };
  },
  computed: {
    newUsers() {
      return this.users.filter(({ status }) => status === USER_STATUSES.NEW);
    },
    approvedUsers() {
      return this.users.filter(
        ({ status }) => status === USER_STATUSES.APPROVED
      );
    },
    rejectedUsers() {
      return this.users.filter(
        ({ status }) => status === USER_STATUSES.REJECTED
      );
    },
    visibleUsers() {
      switch (this.activeTab) {
        case 1: // USER_STATUSES.APPROVED
          return this.approvedUsers;
        case 2: // USER_STATUSES.REJECTED
          return this.rejectedUsers;
        default:
          return this.newUsers;
      }
    },
  },
  mounted() {
    this.users = users.slice(0, 25).map(name => {
      const science = sciences[Math.floor(Math.random() * sciences.length)];
      return {
        name,
        science,
        status: USER_STATUSES.NEW,
      };
    });
  },
};
</script>

<style>
.bordered-bottom {
  border-bottom: 1px solid #ddd;
}
</style>
